import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-4a27ad1bed/0/cache/next-npm-15.2.0-canary.2-708a3ca161-946f95d455.zip/node_modules/next/dist/lib/metadata/metadata-boundary.js");
